/* PreferencesForm.css */

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100%;
    max-width: 375px; /* Control the max width of the form */
    margin: 0 auto; /* Centering the form in the page */
}

.shopbot-title {
    text-align: center;
    margin-top: 5px;
    font-size: 1.2em;
    font-weight: 700;
}

.header-text {
    font-family: 'Roboto', sans-serif; /* Ensuring Roboto is used */
    font-size: 18px; /* Setting the font size */
    font-style:italic;
	font-weight: bold; /* Making the font bold */
    margin-top: 10px; /* Top margin */
    margin-bottom: 10px; /* Bottom margin */
    width: 250px; /* Width to wrap text */
    text-align: center; /* Center text alignment */
    margin-left: auto; /* Centering the text block horizontally */
    margin-right: auto; /* Centering the text block horizontally */
    padding-left:40px;
	padding-right:40px;
	word-wrap: break-word; /* Ensures the text wraps to prevent overflow */
}

.form-label {
    font-family: 'Roboto', sans-serif; /* Ensuring Roboto is used */
    font-size:18px;
	font-weight:600;
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
/* Add this to your CSS file */
label.form-label {
  margin-bottom: 10px; /* Adjust as needed */
}

label.form-label .label-name {
  margin-bottom: 30px; /* Adjust as needed */
}
label.form-label .label-value {
  margin-bottom: 30px; /* Adjust as needed */
  font-size:30px;
}

input[type="checkbox"],
input[type="radio"] {
  margin-bottom: 5px; /* Adjust as needed */
  margin-top:15px;
  margin-right:5px;
}

.input-field {
    font-family:'Roboto-Condensed', sans-serif;
    font-weight: 400;
    height:50px;
	width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    resize: vertical; /* Allow vertical resizing */
}

.input-field:focus {
    border-color: #4A90E2;
    outline: none;
}

.form-navigation {
    display: flex;
    justify-content: space-between;
    width: 95%; /* Ensures navigation buttons align with the input fields */
}

.form-navigation button {
    flex-basis: 35%; /* Splits space evenly between buttons, accounting for margins */
    margin-left:10px;
	padding: 10px;
    background-color: #30BBF7;
    color: white;
	border-color:#d6d6d6;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
	
}

.form-navigation button:hover {
    background-color: #367c39;
}

.submit-button {
    background-color: #555555;
}

.submit-button:hover {
    background-color: #333333;
	
}

.preferences-form div:last-child {
  margin-bottom: 20px; /* Adjust as needed */
}


/* Ensure the form is responsive on smaller screens */
@media (max-width: 600px) {
	.form-label,
    .input-field,
    .form-navigation button {
        font-size: 18px; /* Smaller font size for mobile devices */
    }

    .header-text,
    form {
        padding: 10px;
        width: 80%; /* Smaller width for mobile to allow some space on the sides */
    }

}