body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.header-container {
  display: flex;
  justify-content: center;
  /* Centers child elements horizontally */
  align-items: center;
  /* Centers child elements vertically */
  width: 100%;
  /* Ensures the container takes the full width */
  position: relative;
}

.shopbot-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  font-size: 1.2em;
  font-weight: 700;
}

@font-face {
  font-family: 'SiBi-Regular';
  src: local('SiBi-Regular'), url('../fonts/SiBi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.sibi-text {
  font-family: 'SiBi-Regular', sans-serif;
  color: #30BBF7;
  font-weight: 700;
  font-style: italic;
  letter-spacing: .075em;
  font-size: 1.45em;
  padding-right: 5px;
  text-shadow: 
      -1px  1px 0 #000,
       1px  1px 0 #000;
}

.genai-text {
  font-family: 'Montserrat', sans-serif; 
  font-size: 1.55em;
  font-weight: 700;
  font-style: regular;
  color: #626262;
  letter-spacing: -.05em;
}

.menu-component {
  margin-left: 20px; /* Add space between GPT text and menu */
}

.rec-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 700;
  color: #0075ff;
  letter-spacing: -.05em;
  margin-top: 10px;
}

.prompt-input {
  font-family: 'Roboto-Condensed', sans-serif;
  font-weight: 400;
  font-size: 16px;
  height: auto;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
  margin-right: 5px;
  resize: vertical;
}

.mod-input {
  font-family: 'Roboto-Condensed', sans-serif;
  font-weight: 500;
  font-size: 16px;
  height: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
  margin-right: 10px;
  resize: vertical;
}

.header-text {
  font-family: 'Roboto', sans-serif;
  /* Ensuring Roboto is used */
  font-size: 16px;
  /* Setting the font size */
  font-weight: bold;
  /* Making the font bold */
  text-align: center;
  /* Centering the text */
  margin-top: 10px;
  /* Top margin */
  margin-bottom: 10px;
  /* Bottom margin */
}

.response-text {
  text-align: center;
  font-family: 'Roboto-Condensed', sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  color: #000000;
  padding: 5px;
  border-width: 0px;
  border: 0px solid #ccc;
  border-radius: 0px;
  flex-grow: 0;
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 10px;
  min-width: 325px;
  max-width: 350px;
  background-color: #ffffff;
  resize: vertical;
}

.prompt-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  width: 90%;
  max-width: 350px;
  margin: 5px auto;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  /* Ensure relative positioning */
}

.modify-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 250px;
  margin: 5px auto;
  padding: 5px;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.response-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: 0px solid #ccc;
  border-radius: 0px;
  width: 90%;
  max-width: 350px;
  margin: 0px auto;
  padding: 0px;
  background-color: #ffffff;
  resize: none;

  flex-direction: column;
  /* Stack children vertically */
  width: 100%;
  /* Ensure full width as needed */

}

.prompt-input:focus {
  outline: none;
}


.icon {
  margin-right: 0px;
  width: 30px;
  height: 30px;

}


.icon.prompt-icon {
  margin: 0px;
  padding: 0px;
  max-width: 30px;
  max-height: 30px;
  background-color: #D9D9D9;

}


.color-wheel-icon {
  background: #f3f3f3;
  border: 0px solid #ccc;
  padding: 0px;
  margin-bottom: 3px;
  margin-right: 0px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 0%;
  cursor: pointer;
  outline: none;
  transition: 0.2s;
  transform: 0.2s;

}

.color-wheel-icondepressed {
  margin-right: 0px;
  padding: 0px 1px 0px 1px;
  padding-inline: 0px 0px 0px 0px;
  padding-block: 1px;
  border-color: #757575;
  background-color: #b8b8b8;
  background-color: #ffffff;
}

.image-icon {
  margin: 0px;
  padding-inline: 0px;
  padding-block: 0px;
  margin-left: 5px;
  padding-right: 0px;
  border: 0px;
  max-width: 28px;
  max-height: 28px;
  background-color: #f0f0f0;
  opacity: 0.8;
  transition: filter 0.2s ease;
  /* Smooth transition for greyscale effect */
}

.image-icon-button {
  margin-left: 0px;
  padding-inline: 0px;
  padding-block: 0px;
  border: 0px;
  background-color: #f0f0f0;
  transition: filter 0.2s ease-in-out;
  /* Example transition on filter effect */
}

.image-icon-button.animate {
  transform: scale(0.95);
  /* Slightly scale down to mimic depression */
}

.image-icon-button:not(.animate) .image-icon {
  filter: grayscale(100%);
  /* Greyscale effect for not depressed state */
}

.arrow-icon {
  margin: 0px;
  padding-inline: 0px;
  padding-block: 0px;
  margin-left: 5px;
  padding-right: 0px;
  border: 0px;
  max-width: 28px;
  max-height: 28px;
  background-color: #f0f0f0;
  opacity: 0.8;
  transition: filter 0.2s ease;
  /* Smooth transition for greyscale effect */

}

.arrow-icon-button {
  margin-left: 0px;
  padding: 0px;
  background-color: #f0f0f0;
  border: 0px;
  transition: filter 0.2s ease-in-out;
  /* Example transition on filter effect */

}

.arrow-icon-button.animate {
  transform: scale(0.95);
  /* Slightly scale down to mimic depression */
}

.arrow-icon-button:not(.animate) .arrow-icon {
  filter: grayscale(100%);
  /* Greyscale effect for not depressed state */
}


.generate-button,
.modify-button {
  height: 30px;
  margin-top: 0px;
  margin-left: 5px;
  padding: 0 5px;
  border: 1px solid gray;
  background-color: #D9D9D9;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.how-to {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  max-width: 375px;
  /* Control the max width of the form */
  margin: 0 auto;
  /* Centering the form in the page */
}

.how-to-image {
  width: 100%;
  max-width: 375px;
  /* Control the max width of the form */
  margin: 0 auto;
  /* Centering the form in the page */
}

.dismiss-button {
  flex-basis: 50%;
  /* Splits space evenly between buttons, accounting for margins */
  align-self: flex-end;
  width: 120px;
  margin-left: 10px;
  margin-right: 30px;
  padding: 10px;
  background-color: #30BBF7;
  color: white;
  border-color: #d6d6d6;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


}

.dismiss-button:hover {
  color: white;
  background-color: #367c39;
}

.our-picks-button {
  font-family: arial;
  font-weight: 600;
  margin: 5px auto;

  width: 60%;
  max-width: 350px;
  padding: 10px 20px;
  border: 1px;
  border-style: solid;
  border-color: #cfcfcf;
  background-color: #B9F4FF;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.our-pics-button:hover {
  background-color: #000000;
}

.generate-button:hover,
.modify-button:hover {
  background-color: #B9F4FF;
}

.api-response-container {

  font-family: arial;
  font-weight: 500;
  font-size: .9em;
  background-color: white;
  padding: 0 10px 0 10px;

  width: 80%;
  max-width: 400px;
  margin: 5px auto;

  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 30px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.api-response {
  color: black;
}



.custom-image-container {
  position: relative;
  width: auto;
  height: auto;
  margin-top: 10px;
}

.custom-image-placeholder {
  position: relative;
  margin: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  max-width: 250px;
  max-height: 308px;
}

.custom-image-placeholder img {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 20px;

}

.error-message {
  font-size: 14px;
  max-width: 300px;
  margin: auto;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  word-wrap: break-word;
  text-align: center;
  color: red;
}

.find-match-button {
  float: right;
  margin-bottom: 5px;
}


.boundingBoxStyle {
  border-radius: 10px;
  border: 2px solid black;
}

.image-placeholder-container {
  Margin-top: 10px;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  max-width: 300px;
}

.image-placeholder {
  flex-basis: calc(50% - 30px);
  margin: 5px;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

.image-container {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.image-container img {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.image-placeholder img {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.search-icon-button {
  position: absolute;
  right: 0px;
  bottom: 3px;
  border: none;
  background: white;
  background: rgba(255, 255, 255, 0.5);
  /* White with 50% opacity */
  cursor: pointer;
}

.search-icon-button img {
  width: 20px;
  height: 20px;
  padding: 1px;
  padding-inline: 2px;
}

.product-name {
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: 'Arial';
  font-size: 10px;
  font-weight: bold;
  color: #000000;

}

.product-details {
  font-family: 'Arial';
  font-size: .8em;
  font-weight: bold;
  color: #000000;

}

.product-price {
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: 'Times New Roman', serif;
  font-size: 12px;
  font-weight: 600;
  color: #888;

}




.color-wheel-icon.depressed {}


.color-wheel-icon .icon {
  display: block;
  width: 30px;
  height: 30px;
  transition: opacity 0.2s;
}


.color-wheel-icon.depressed .icon {
  opacity: 0.7;
}


.color-picker-container {
  position: relative;
  margin-top: 10px;
}



.react-color-chrome {
  border: 2px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}




.react-color-chrome input {
  border: 1px solid #aaa !important;
}

.response-area {
  margin-top: 20px;
  width: 100%;
}


.container {
  padding: 20px;
}


@media (max-width: 768px) {
  .sibi-text {
    letter-spacing: -.02em;
    /* Tighter letter spacing on mobile */
  }
}

@media (min-width: 768px) {
  .container {
    padding: 40px;
  }
}


@media (min-width: 1024px) {
  .container {
    padding: 60px;
  }
}

.draggable {
  border-radius: 5px;
}

.resizable {
  border-radius: 5px;
}

.bounding-box {
  border-radius: 5px;
}

.bounding-box-styled {
  border-radius: 5px;
}

.custom-resize-handle {
  width: 15px;
  height: 15px;
  background-color: #45e2ff;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
}

.custom-resize-handle-top {
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.custom-resize-handle-right {
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
}

.custom-resize-handle-bottom {
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.custom-resize-handle-left {
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
}

.custom-resize-handle-topRight {
  top: -10px;
  right: -10px;
}

.custom-resize-handle-bottomRight {
  bottom: 0px;
  right: 0px;
}

.custom-resize-handle-bottomLeft {
  bottom: -10px;
  left: -10px;
}

.custom-resize-handle-topLeft {
  top: -10px;
  left: -10px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.recent-searches-dropdown {
  position: absolute;
  top: 100%;
  /* Position it just below the container */
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 5px;
  /* Add margin to separate it from the container */
}

.recent-searches-dropdown h3 {
  color: #3498db;
  padding-left: 10px;
}

.recent-searches-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.recent-searches-dropdown li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.recent-searches-dropdown li:hover {
  background-color: #f0f0f0;
}

.recent-searches-dropdown li .remove-search {
  margin-left: 10px;
  color: red;
  cursor: pointer;
}
